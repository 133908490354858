(function () {
  'use strict';

  /* @ngdoc object
   * @name person.information
   * @description
   *
   */
  angular
    .module('neo.home.persons.person.information', [
      'ui.router','neo.home.persons.newPerson'
    ]);
}());
